.subHeader {
    margin: 3px;
    font-size: 14px;
    cursor: default;
    min-width: fit-content;
}

.subBanner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 4px;
    height: 32px;
}

.flexContainer {
    display: flex;
    align-items: center;
}

.childFlexContainer {
    display: flex;
    width: 100%;
    align-items: center;
}

.flexStart {
    justify-content: flex-start;
}

.flexEnd {
    justify-content: flex-end;
}

.subHeaderFilterInput {
    margin: 2px;
}