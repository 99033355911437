.buttons {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.children {
    height: 100%;
}

.progressStatusContainer {
    width: 100%;
    margin-bottom: 10px;
}

.progressSpinner {
    display: flex;
    align-items: center;
    justify-content: space-between;
}