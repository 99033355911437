body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ag-theme-balham .ag-header-group-cell-label {
  justify-content: center;
  font-weight: 900;
}

.ag-theme-balham .ag-ltr .ag-cell {
  border-left: 1px solid rgba(0, 0, 0, 0.20);
}

.ag-theme-balham .ag-group-value {
  width: 100%;
}

.ag-theme-balham {
  --ag-value-change-value-highlight-background-color: #5BBF2190;
  --ag-row-border-color: rgba(0, 0, 0, 0.20);
  --ag-border-color: rgba(0, 0, 0, 0.20);
  --ag-header-column-separator-color: rgba(0, 0, 0, 0.20);
  --ag-odd-row-background-color: #ffff !important;
  --ag-even-row-background-color: #ffff !important;
}