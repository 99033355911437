.filterInput {
    width: 200px;
    height: 23px;
    border: 1px solid #cccccc70;
    padding: 0px 4px;
}

.filterInput:hover {
    background-color: #efefef90;
}

.filterInput:active {
    border: 1px solid #cccccc70;
    background-color: #efefef;
}