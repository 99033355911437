.loadingSpinner {
    text-align: center;
    font-size: 14px;
}

.progressStatus {
    width: 100%;
}

.progressContainer {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
}

.progressContainer>p {
    margin: 0px;
    padding: 3px;
}