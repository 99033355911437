.calculationsBarContainer {
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
    align-content: flex-start;
    padding-right: 3px;
    width: calc(50% - 2.5px);
    height: 100%;
}

.loadingSpinnerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(50% - 2.5px);
}

.fieldsContainer {
    display: flex;
    flex-direction: column;
    max-width: 160px;
    min-width: 140px;
    height: fit-content;
    padding: 10px;
    flex: 1;
}

.fieldsHeader {
    margin: 2px 0px 0px 0px;
    padding-bottom: 2px;
    text-align: center;
    border-bottom: 1px solid black;
}