.actionHeader {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid rgba(0, 0, 0, 0.20);
    height: 32px;
    padding: 0px 4px;
    align-items: center;
}

.actionContainer {
    display: flex;
}

.filterInputContainer {
    margin: 2px;
}

.accountTemplateSelectContainer {
    margin: 0px 6px;
}