.TooltipIconButton {
    color: rgb(0, 120, 212);
    border: none;
    background-color: transparent;
    padding: 5px;
    height: 32px;
    width: 32px;
    cursor: pointer;
}

.TooltipIconButton:hover {
    background-color: #f5f7f7;
}