.confirmBody {
    display: flex;
    flex-direction: column;
}

.prognosisBody {
    max-width: 240px;
}

.saveBody {
    min-width: 275px;
    max-width: 350px;
}

.snapshotBody {
    max-width: 280px;
}

.reloadErrorBody {
    max-width: 400px;
}

.startBody {
    justify-content: space-evenly;
}

.loadingBody {
    justify-content: center;
    align-content: center;
}

.buttonContainer {
    display: flex;
    justify-content: center;
    padding: 10px 0px;
}

.buttonMargin {
    margin: 0px 10px;
}

.messageContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.statusMessage {
    margin: 0px 0px 5px 0px !important;
}

.showEditsContainer {
    margin-top: 20px;
}

.spinnerContainer {
    padding: 5px;
}

.hiddenSpinner {
    visibility: hidden;
}

h4 {
    margin: 0px;
}


label {
    font-size: 12px;
    font-style: italic;
    margin: 2px 0px;
}

.input {
    width: calc(100% - 10px);
}

.inputContainer {
    margin-bottom: 14px;
}