input::-webkit-calendar-picker-indicator {
    cursor: pointer;
}

.defaultDatePicker {
    font-family: inherit;
    outline: none;
    border: 1px solid #cccccc;
    padding: 3px;
    margin: 2px;
    height: 17px;
}