.contentContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
}

.sectionMargin {
    margin: 20px 0px;
}

.headerRow {
    display: flex;
    justify-content: space-between;
}

.checkAllCheckboxes {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.checkboxes {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: flex-start;
}

.checkbox {
    display: flex;
    width: 140px;
    padding: 2px 0px;
    margin: 0px 2px;
}

.header {
    font-size: 14px;
    margin: 2px 0px;
}

.datePickers {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.datePicker {
    display: flex;
    flex-direction: column;
    width: calc(50% - 10px);
}