.accountSpecificationGridContainer {
    height: 70vh;
    width: 70vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.accountSpecificationGrid {
    height: calc(100% - 30px);
    width: 100%;
}

.accountSpecificationGridHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
}

.accountSpecificationGridDescription {
    padding: 0px 10px;
    margin: 0px !important;
    font-style: italic;
}