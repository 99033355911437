.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100% - 20px);
    width: 100%;
    padding-bottom: 20px;
    min-height: 70px;
}

.message {
    font-size: 15px;
    margin: 0px !important;
}

.topBorderOnError {
    width: 100%;
    height: 100%;
    border-top: 1px solid rgba(0, 0, 0, 0.20);
}