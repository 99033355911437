.internalCreditsGridContainer {
    height: 100%;
    width: 100%;
}

.internalCreditsGrid {
    height: calc(100% - 30px);
    width: 100%;
}

.internalCreditsSumContainer {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-end;
    height: 30px;
    width: 100%;
}

.internalCreditsGridSum {
    font-weight: 700;
}