.mainHeader {
    margin: 6px;
    font-size: 20px;
    cursor: default;
}

.mainBanner {
    background-color: rgb(0, 120, 212);
    color: white;
    padding: 2px;
    display: flex;
    justify-content: space-between;
}

.logoTitle {
    display: flex;
}

.buttonContainer {
    padding-top: 4px;
}