.flexContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.addInternalCreditContainer {
    width: 100%;
    margin-bottom: 4px;
}

.submitContainer {
    display: flex;
    justify-content: flex-end;
    width: 110px;
}

.inputsContainer {
    display: flex;
    width: 100%;
    align-items: center;
}

.hiddenLoadingSpinner {
    margin: 8px;
    visibility: hidden;
}

.visibleLoadingSpinner {
    margin: 8px;
    visibility: visible;
}