.resCalcLogsContainer {
    height: calc(100% - 74px);
}

.resCalcLog {
    height: 100%;
}

.resCalcLogWidth {
    width: 40%;
}

.resCalcLogDetailsWidth {
    width: 60%;
}

.resCalcLogGrid {
    height: calc(100% - 34px);
}

.headerContentsContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
}

.headerTitle {
    margin: 0px 4px;
    font-size: 12px;
}

.headerInfo {
    font-size: 12px;
    font-style: italic;
    margin: 0px 4px;
}

.sliderContainer {
    width: 200px;
    max-width: 300px;
    min-width: 100px;
}

.filterInputContainer {
    margin: 2px;
}