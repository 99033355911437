.snapshotSelect {
    width: 250px;
    height: 25px;
    margin: 2px;
    border: 1px solid #cccccc70;
}

.snapshotSelect:hover {
    background-color: #efefef90;
}

.snapshotSelect:active {
    background-color: #efefef90;
}