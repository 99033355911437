.adminToolsContainer {
    display: flex;
    justify-content: space-between;
    margin: 30px 20px;
    font-size: 14px;
    max-width: 1300px;
}

.adminToolsColumn {
    display: flex;
    flex-direction: column;
    padding: 0px 30px;
}

.userDataContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 200px;
    max-height: 100%;
}

.userData {
    margin: 30px 0px;
    padding: 0px 10px;
    width: calc(100% - 20px);
    border: 1px solid #ccc;
}

.buttonContainer {
    padding: 10px 0px;
}

.formContainer {
    display: flex;
    flex-direction: column;
    width: max-content;
    margin-bottom: 40px;
}

.inputGroupHeader {
    margin: 10px 0px;
    font-size: 12px;
}

.inputRow {
    display: flex;
}

.inputSpaceRow {
    padding: 5px 0px;
    display: flex;
    justify-content: space-between;
}

.inputCol {
    display: flex;
    flex-direction: column;
}



.standardValueInput {
    width: 50px;
}

.emailInput {
    width: 200px;
}

.textInput {
    width: 100px;
}

.unitInput {
    width: 70px;
    margin-right: 4px;
}

.messageTextArea {
    width: 400px;
    padding: 5px;
    resize: none;
    font: inherit;
}



.labelPadding {
    padding-right: 5px;
}

.usageInstruction {
    margin-top: 0px;
}