.infoTooltipWrapper {
    display: flex;
    align-items: center;
    padding: 5px;
}

.infoTooltip {
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.infoTooltipHeader {
    margin: 0px;
}

.infoText {
    font-size: 14px;
    margin: 0px;
    padding-top: 4px;
}

.infoHeader {
    margin-top: 14px;
    font-size: 14px;
    font-weight: 600;
}

.regularColor {
    color: gray;
}

.calculatedColor {
    color: blue;
}

.manualColor {
    color: orange;
}

.vatFreeColor {
    color: green;
}

.prognosisLessThanOutcome {
    color: red;
}

.infoContainer {
    display: flex;
    justify-content: flex-start;
    padding-top: 4px;
}

.projectStageDescription {
    font-size: 14px;
    margin-right: 14px;
    color: gray;
}

.projectStageColorBox {
    height: 12px;
    width: 12px;
    border: 1px solid black;
    margin: 3px 5px;
}

.projectionStageColor {
    background: #8B572980;
}

.buildingStageColor {
    background: #AACEED80;
}

.usingStageColor {
    background: #B6D09780;
}

.isOutcome {
    background: #A7AED880;
}

ul.infoList {
    list-style-type: none;
    padding: 0px 10px;
    margin: 0px;
}