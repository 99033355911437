.errorsContainer {
    display: flex;
    flex-direction: column;
    max-height: 50vh;
    min-width: 300px;
    max-width: 500px;
}


.clearButton {
    padding-top: 20px;
    display: flex;
    justify-content: center;
}

.errorMessageBorder {
    border-top: 1px solid black;
}

.errorMessageRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.errorMessage {
    margin: 0px !important;
    padding-right: 8px;
    padding-bottom: 8px;
}

.notFirstErrorMessage {
    padding-top: 8px;
}