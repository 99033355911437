.dateInputContainer {
    display: flex;
    flex-direction: column;
}

.dateInputLabel {
    display: flex;
    align-items: flex-end;
    font-size: 12px;
    font-style: italic;
    height: 30px;
    margin: 2px 0px;
}

.dateInput {
    padding: 2px 6px;
    width: calc(100% - 12px);
    font-family: inherit;
}

.dateInputHolder {
    border: 1px solid #cccccc;
    display: flex;
    align-items: center;
    padding: 3px 0px 3px 12px;
    height: 21.5px;
}

.dateInputHolder>input {
    border: 0;
    margin: 0;
    padding: 0;
    outline: none;
}

.dateInputHolder:after {
    content: '';
}

.dateInputHolder:focus-within {
    border: 1px solid #0078D4;
}

input:invalid {
    color: red;
}