.resultCalcHeaderSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 50%;
}

.resultCalcHeaderSubSection {
    display: flex;
    align-items: center;
}