.actionButton {
    padding: 3px;
    margin: 2px;
    cursor: pointer;
    min-width: fit-content;
    height: 25px;
}

.actionButton:hover {
    background-color: #efefef90;
}


.actionButton:disabled {
    background-color: #efefef;
    border: 1px solid #cccccc70;
    color: rgba(16, 16, 16, 0.3);
    cursor: default;
}

.actionButton:disabled:hover {
    background-color: #efefef90;
}

/* Default buttons */
.defaultButton {
    border: 1px solid #cccccc70;
    background-color: #efefef;
    white-space: nowrap;
}

/* Save project economy button */
.saveButton {
    border: 1px solid rgb(0, 120, 212);
    background-color: rgb(0, 120, 212, 0.9);
    color: #fff;
}

.saveButton:hover {
    background-color: rgb(0, 120, 212, 0.8);
}

.saveButton:disabled {
    background-color: #efefef;
    border: 1px solid #cccccc70;
    color: rgba(16, 16, 16, 0.3);
}

.saveButton:disabled:hover {
    background-color: #efefef90;
}