.contentContainer {
    display: flex;
    flex-direction: column;
    max-width: 370px;
    word-break: break-word;
}

.feedbackText {
    text-align: center;
}

.warningText {
    color: red;
}

.buttonContainer {
    display: flex;
    justify-content: center;
}

.checkboxContainer {
    padding: 10px 0px;
}