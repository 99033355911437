.inputContainer {
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.inputLabel {
    margin-right: 2px;
}

.amountInput {
    width: 60px;
    height: 23px;
    margin: 2px 4px;
    padding: 0px 4px;
    border: 1px solid #cccccc70;
}