.defaultSelect {
    height: 25px;
    margin: 2px 4px;
    border: 1px solid #cccccc70;
}

.defaultSelect:hover {
    background-color: #efefef90;
}

.defaultSelect:active {
    background-color: #efefef90;
}

.inputLabel {
    margin-right: 5px;
}