.percentInputContainer {
    display: flex;
    flex-direction: column;
}

.percentInputLabel {
    display: flex;
    align-items: flex-end;
    font-size: 12px;
    font-style: italic;
    height: 30px;
    margin: 2px 0px;
}

.percentInput {
    padding: 2px 6px;
    width: calc(100% - 12px);
    font-family: inherit;
}

.percentInputHolder {
    border: 1px solid #cccccc;
    display: flex;
    padding: 3px 12px;
    display: flex;
    justify-content: space-around;
    height: 21.5px;
}

.percentInputHolder>input {
    border: 0;
    margin: 0;
    padding: 0;
    outline: none;
}

.percentInputHolder:after {
    content: '%';
    color: gray;
    display: flex;
    align-items: center;
}

.percentInputHolder:focus-within {
    border: 1px solid #0078D4;
}

.belowLimitPercentInputHolder {
    border: 1px solid red;
    display: flex;
    padding: 3px 12px;
    display: flex;
    justify-content: space-around;
    height: 21.5px;
}

.belowLimitPercentInputHolder>input {
    border: 0;
    margin: 0;
    padding: 0;
    outline: none;
}

.belowLimitPercentInputHolder:after {
    content: '%';
    color: gray
}

input:disabled {
    color: gray;
    background-color: transparent;
}