.resCalcSchedulerGrid {
    height: calc(100% - 34px);
    width: 50%;
}

.datePicker {
    font-family: inherit;
    border: none;
    background: transparent;
    outline: none;
}